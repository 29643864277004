import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {Directory} from '../models/directory';
import {HttpClient} from '@angular/common/http';
import {Page} from '../models/page';

@Injectable({
  providedIn: 'root'
})
export class LegalFormService {

  private url = 'api/legal-form-directory';

  constructor(
    private httpClient: HttpClient,
  ) {
  }

  create(formData) {
    return this.httpClient.post(this.url, formData);
  }

  getCatalogs() {
    return this.httpClient.get<any[]>(this.url);
  }

  getData(page: number, count: number) {
    return this.httpClient.get<Page<any>>(`${this.url}/${page}/${count}`).pipe(
      map(res => {
        for (let index = 0; index < res.page.length; index++) {
          res.page[index] = new Directory(res.page[index]);
        }
        return res;
      })
    );
  }

  activate(id) {
    return this.httpClient.get(`${this.url}/activate/${id}`);
  }

  deactivate(id) {
    return this.httpClient.get(`${this.url}/deactivate/${id}`);
  }
}
