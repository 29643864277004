import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FinishPeriodService {
  private url = 'api/actualization-periods/'

  constructor(
    private httpClient: HttpClient
  ) {}

  closeActualizationCompanyPeriod () {
    return this.httpClient.post(`${this.url}close-actualization-company-level-1`, {})
  }

  closeActualizationCompanyPeriodSecond () {
    return this.httpClient.post(`${this.url}close-actualization-company-level-2`, {})
  }
}
