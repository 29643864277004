import {Injectable} from '@angular/core';
import {
  IBioDetail,
  Position,
  PositionAdding,
  Role,
  UserCatalogsId,
  UserDetail,
  UserFilter,
  UserHistory,
  UsersDirectories,
  UserShort
} from '../models/user';
import {map} from 'rxjs/operators';
import {ServiceService} from './service.service';
import {Observable} from 'rxjs';
import {Page} from '../models/page';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  // адрес сервиса
  private url = 'api/users';

  constructor(
    private httpClient: HttpClient,
    private service: ServiceService,
  ) {
  }

  registration(user: UserCatalogsId) {
    const formData = this.service.prepareFormData(user, 'appointmentFileData', 'appointmentFile', 'json');
    return this.httpClient.post<UserDetail>(this.url + '/singup', formData);
  }

  createUser(user: UserCatalogsId) {
    const formData = this.service.prepareFormData(user, 'appointmentFileData', 'appointmentFile', 'json');
    return this.httpClient.post<UserDetail>(this.url, formData);
  }

  update(id: number | string, user: UserCatalogsId) {
    const formData = this.service.prepareFormData(user, 'appointmentFileData', 'appointmentFile', 'json');
    return this.httpClient.put<UserDetail>(this.url + '/' + id, formData);
  }

  checkUserByTin(tin: number | string) {
    return this.httpClient.get<boolean>(`${this.url}/tin/${tin}`, {headers: {'Background-Loading': 'true'}});
  }

  getByFilter(page: number, count: number, filter: UserFilter) {
    return this.httpClient.post<Page<UserShort>>(`${this.url}/${page}/${count}`, filter);
  }

  checkUserByUsername(username: string) {
    return this.httpClient.patch<boolean>(
      `${this.url}/username/${username}`,
      {'username': username},
      {headers: {'Background-Loading': 'true'}});
  }

  getCatalogs() {
    return this.httpClient.get<UsersDirectories>(this.url + '/directories');
  }

  getById(id: number | string) {
    return this.httpClient.get<UserDetail>(this.url + '/' + id);
  }

  getCurrentUser() {
    return this.httpClient.get<IBioDetail>(this.url + '/me');
  }

  block(id: number, formData: number) {
    return this.httpClient.post<UserDetail>(this.url + '/deactivate/' + id, formData);
  }

  unblock(id: number, formData: number) {
    return this.httpClient.post<UserDetail>(this.url + '/activate/' + id, formData);
  }

  approve(id: number) {
    return this.httpClient.post<boolean>(this.url + '/approve/' + id, id).pipe(
      map(
        () => {
          return true;
        }
      )
    );
  }

  getByUsername(username: string) {
    return this.httpClient.get<UserDetail>(`${this.url}/username/${username}`, {headers: {'Background-Loading': 'true'}});
  }

  checkEmailNotTaken(username: string): Observable<Boolean> {
    return this.getByUsername(username).pipe(
      map(user => {
        return !!user;
      })
    );
  }

  getHistoryOfUser(user_id: number, page: number, count: number) {
    return this.httpClient.get<Page<UserHistory>>(`${this.url}/detail-history/${user_id}/${page}/${count}`);
  }

  findByCompanyIds(value: Array<number | string>) {
    return this.httpClient.post<UserShort[]>(`${this.url}/company/find`, value);
  }

  updatePassword(formData) {
    return this.httpClient.put<UserDetail>(`${this.url}/password`, formData);
  }

  getTmpCatalogs() {
    return this.httpClient.get<UsersDirectories>(this.url + '/temp-directories');
  }

  sendNewPasswordToEmail(username: string) {
    return this.httpClient.patch<boolean>(`${this.url}/password`, username, {headers: {HideError: 'true'}}).pipe(
      map(() => true)
    );
  }

  getByPositionAndCompany(page: number, count: number, positionId: number, companyId: number) {
    return this.getByFilter(page, count, {
      positionId,
      companyId
    });
  }

  getAllUserTypes() {
    return this.httpClient.get<Position[]>(`${this.url}/user-types`);
  }

  getRoles() {
    return this.httpClient.get<Role[]>(`${this.url}/roles`);
  }

  addUserType(formData: PositionAdding) {
    return this.httpClient.post<Position>(`${this.url}/user-types`, formData);
  }

  setUserTypeActive(userTypeId: number, isActive: boolean) {
    return this.httpClient.put<Position>(`${this.url}/user-types/${userTypeId}/${isActive}`, {userTypeId, isActive});
  }

  updateUserType(userTypeId: number, body: any) {
    return this.httpClient.put(`${this.url}/user-types/${userTypeId}`, body);
  }

  getUsersListExport(body: any): Observable<any> {
    return this.httpClient.post(`${this.url}/xlsx`, body, {responseType: 'blob'});
  }

  getPrincipal(): Observable<UserDetail> {
    return this.httpClient.get<UserDetail>(`${this.url}/principal/me`);
  }
}
