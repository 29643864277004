import { Expose } from 'class-transformer'

export namespace Search {
  export class Model<T> {
    @Expose()
    pageNumber: number

    @Expose()
    pageSize: number

    @Expose()
    totalPages: number

    @Expose()
    totalElements: number

    @Expose()
    content: T[]
  }
}
